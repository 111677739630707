import { css, Global } from '@emotion/react'
import { memo } from 'react'
import { useThemeStore } from '~/components/theme/useThemeStore'

export const GlobalParentsHeight100 = memo<ReactProps>(function GlobalParentsHeight100(props) {
  const themeMode = useThemeStore(state => state.theme)

  return (
    <Global
      styles={[
        themeMode === 'dark'
          ? css`
              html {
                background-color: black;
              }
            `
          : css`
              html {
                background-color: white;
              }
            `,
        css`
          html,
          body,
          #__body,
          #__next {
            height: 100%;

            /*
            修正 ios safari 底下那個動來動去的網址列，會造成滿版高設計的投顧，底下老是被蓋住的問題

            Avoid Chrome to see Safari hack

            @see https://github.com/postcss/postcss-100vh-fix
          */
            @supports (-webkit-touch-callout: none) {
              min-height: fill-available;
            }
          }
        `,
      ]}
    ></Global>
  )
})
